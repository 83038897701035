<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">助成手続き書類 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                @change="fetchList"
                v-model="search.year"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="seriesList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchShort inlineBlock searchSpace">
            <div>文理区分</div>
            <div>
              <b-form-select
                v-model="search.bunri"
                :options="bunriOption">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>報告者名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchShort inlineBlock searchSpace">
            <div>受付状況</div>
            <div>
              <b-form-select
                v-model="search.requiredDoctypeStatus"
                :options="requiredDoctypeStatusOption">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchShort inlineBlock">
            <div>ステータス</div>
            <div>
              <b-form-select
                v-model="search.status"
                :options="statusList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsRequiredDocortListTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="requiredDocList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 link" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 link" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 link" @click="allSelect">全選択</p>
                <p class="mb-0 link" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedRequiredDocIdList($event,row.item.id)"
                :checked="selectedAppidList"
                :value="row.item.id"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(apptype_name)="row">
            {{row.item.apptype_name}} 報告
          </template>
          <template v-slot:cell(statusText)="row">
            <span :class="[row.item.statusText==='確認中' ? 'red-text' : '']">{{row.item.statusText}}</span>
          </template>
          <template v-slot:cell(btn)="row">
            <b-link
              v-if="row.item.status !== status['UNSUBMIT'] && row.item.status !== status['TEMPSAVE']"
              :to="'/cms/requiredDoc/edit/'+row.item.requiredDocId">
              詳細
            </b-link>
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canReceipt || search.year === 'all'"
        @click="control(status['RECEIPT'])"
        >一括受領</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        :disabled="!canExportPdf"
        @click="exportPdf()"
        >PDF出力</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-2"
        :disabled="selectedAppidList.length < 1"
        to="/cms/requiredDoc/mail">メール送信</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
import download from '@/modules/download';

export default {
  name: 'CmsApplicationsList',
  data() {
    return {
      search: {
        year: null,
        name: '',
        bunri: '',
        requiredDoctypeStatus: '',
        series: null,
        status: '',
      },
      status: CONST_STATUS.APP_STATUS,
      statusList: CONST_STATUS.CMS_REP_STATUS_OPTION_4_LIST,
      dbRequiredDocList: [],
      header: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '報告者名', sortable: true },
        { key: 'institution', label: '所属', sortable: true },
        { key: 'apptype', label: '助成プログラム', sortable: true },
        { key: 'bunri', label: '文理', sortable: true },
        { key: 'term', label: '提出期間', sortable: true },
        { key: 'requiredDoctypeStatus', label: '受付状況', sortable: true },
        { key: 'statusText', label: 'ステータス', sortable: true },
        { key: 'btn', label: '詳細', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      rawSeriesList: [],
      yearList: [],
      requiredDoctypeStatusOption: ['報告期間外', '受付中'],
      pdfErr: null,
    };
  },
  methods: {
    async fetchList() {
      if (this.search.year !== null && this.search.year !== '' && this.search.year !== 'all') {
        if (this.search.year < 2025) {
          if (this.search.series === '学術調査研究助成') {
            this.search.series = '調査研究助成';
          }
          if (this.search.series === 'デジタルイノベーション社会実装助成') {
            this.search.series = '目的型調査研究助成';
          }
        } else {
          if (this.search.series === '目的型諸活動助成') {
            this.search.series = null;
          }
          if (this.search.series === '調査研究助成') {
            this.search.series = '学術調査研究助成';
          }
          if (this.search.series === '目的型調査研究助成') {
            this.search.series = 'デジタルイノベーション社会実装助成';
          }
        }
      }
      const param = { year: this.search.year };
      const response = await api.send('/api/cms/requiredDoc/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.dbRequiredDocList = response.data.list;
      this.yearList = response.data.yearList;
      if (this.search.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.rawSeriesList = response.data.seriesList;
    },
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const seriesSort = search.series === null || lineData.apptype === search.series || (search.series === '社会的文化的諸活動助成' && lineData.apptype === '社会的・文化的諸活動助成');
      const requiredDoctypeSort = search.requiredDoctypeStatus === '' || lineData.requiredDoctypeStatus === search.requiredDoctypeStatus;
      const bunriSort = search.bunri === '' || lineData.bunri === search.bunri;
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      return nameSort && seriesSort && bunriSort && requiredDoctypeSort && statusSort;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      // 編集ページ内での次の報告、前の報告に移動するためのIDリストの保存
      this.$store.commit('cmsRequiredDocSearch/initSelectedAppid');
      const submitedFilterList = filteredItems.filter((item) => {
        return item.status !== 0;
      });
      this.$store.commit('cmsRequiredDocSearch/setFilterdIdList', submitedFilterList);
      this.$store.commit('cmsRequiredDocSearch/setFilterdAppIdList', filteredItems);
    },
    async control(status) {
      const count = this.selectedRequiredDocIdList.length;
      let msg = `選択中の${count}件の報告のステータスを受領に変更します。`;
      msg += '\nステータスが変更されると、報告者にメールの送信と通知の登録が行われます。\n受領してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        status,
        selectedRequiredDocIdList: this.selectedRequiredDocIdList,
      };
      const response = await api.send('/api/cms/requiredDoc/control', params)
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('選択した助成手続き書類を受領しました。', false);
      await this.fetchList();
      this.$store.dispatch('page/offWaiting');
    },
    setFilter(key, value) {
      this.$set(this.search, key, value);
    },
    allSelect() {
      this.$store.commit('cmsRequiredDocSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsRequiredDocSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdAppIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsRequiredDocSearch/addSelectedAppid', appId);
        const selectedRequiredDocInfo = this.dbRequiredDocList.filter((item) => {
          return item.appId === appId;
        });
        if (selectedRequiredDocInfo && selectedRequiredDocInfo[0].status !== 0) {
          const targetRequiredDocId = selectedRequiredDocInfo[0].requiredDocId;
          this.$store.commit('cmsRequiredDocSearch/addSelectedRequiredDocId', targetRequiredDocId);
        }
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdAppIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsRequiredDocSearch/removeSelectedAppid', appId);
        const selectedRequiredDocInfo = this.dbRequiredDocList.filter((item) => {
          return item.appId === appId;
        });
        if (selectedRequiredDocInfo && selectedRequiredDocInfo[0].status !== 0) {
          const targetRequiredDocId = selectedRequiredDocInfo[0].requiredDocId;
          this.$store.commit('cmsRequiredDocSearch/removeSelectedRequiredDocId', targetRequiredDocId);
        }
      });
    },
    updateSelectedRequiredDocIdList(list, appId) {
      const selectedRequiredDocInfo = this.dbRequiredDocList.filter((item) => {
        return item.appId === appId;
      });
      if (selectedRequiredDocInfo && selectedRequiredDocInfo[0].status !== 0) {
        const targetRequiredDocId = selectedRequiredDocInfo[0].requiredDocId;
        if (list.indexOf(appId) === -1) {
          this.$store.commit('cmsRequiredDocSearch/removeSelectedRequiredDocId', targetRequiredDocId);
        } else {
          this.$store.commit('cmsRequiredDocSearch/addSelectedRequiredDocId', targetRequiredDocId);
        }
      }
      this.$store.commit('cmsRequiredDocSearch/setSelectedAppid', list);
    },
    async exportPdf() {
      if (!this.canExportPdf) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const seriesData = this.seriesList.find(element => element.text === this.search.series);
      this.pdfErr = null;
      const params = {
        selectedRequiredDocIdList: this.selectedRequiredDocIdList,
        seriesId: seriesData.id,
        year: this.search.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/requiredDoc/export/pdf', params, requireBlob)
        .catch(async (err) => {
          const error = JSON.parse(await err.response.data.text());
          if (error && error.msg) {
            this.pdfErr = error.msg;
          }
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        if (this.pdfErr) {
          await this.alert(this.pdfErr);
        } else {
          await this.alert('ファイルのダウンロードに失敗しました。');
        }
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const fileName = `${ymd}_${this.search.series}.pdf`;
      download.blob(response.data, fileName);
    },
  },
  computed: {
    bunriOption() {
      return ['文', '理'];
    },
    requiredDocList() {
      if (!this.dbRequiredDocList) {
        return [];
      }
      const requiredDocList = this.dbRequiredDocList.map((requiredDoc) => {
        let institution = '';
        if ((requiredDoc.name === '国際会議開催助成' || requiredDoc.name === '社会的・文化的諸活動助成') && requiredDoc.ans_apply_org.length > 0) {
          const applyOrg = requiredDoc.ans_apply_org[0];
          institution = applyOrg.field_answer_text;
        } else {
          institution = requiredDoc.university_name;
          if (!institution) {
            // eslint-disable-next-line
            institution = requiredDoc.institution;
          }
        }
        return {
          id: requiredDoc.appId,
          requiredDocId: requiredDoc.requiredDocId,
          sort: requiredDoc.sort,
          apptype: requiredDoc.name,
          name: requiredDoc.user_name,
          bunri: requiredDoc.bunri,
          kana: requiredDoc.user_name_kana,
          year: requiredDoc.year,
          status: requiredDoc.status,
          requiredDoctypeStatus: requiredDoc.reptype_status,
          statusText: requiredDoc.status_text,
          seriesId: requiredDoc.series_id,
          term: `${requiredDoc.from} ~ ${requiredDoc.to}`,
          institution,
        };
      });
      return requiredDocList;
    },
    totalRow() {
      return this.requiredDocList.length;
    },
    adjustedApptypeData() {
      let optionDbData = this.rawSeriesList;
      if (this.search.year === 'all') {
        optionDbData = [];
        this.rawSeriesList.forEach((series) => {
          optionDbData.push(series);
          if (series.id === 2) {
            optionDbData.push({ id: series.id, name: '調査研究助成' });
          }
          if (series.id === 8) {
            optionDbData.push({ id: series.id, name: '目的型調査研究助成' });
          }
        });
      }

      return optionDbData;
    },
    seriesList() {
      const filterSeriesList = [];
      this.adjustedApptypeData.forEach((data) => {
        if ((this.search.year !== null && (this.search.year === 'all' || this.search.year < 2025)) || data.id !== 9) {
          filterSeriesList.push(data);
        }
      });
      const appTypeList = filterSeriesList.map((series) => {
        if (this.search.year !== null) {
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 2) {
            return { value: '調査研究助成', text: '調査研究助成', id: 2 };
          }
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 8) {
            return { value: '目的型調査研究助成', text: '目的型調査研究助成', id: 8 };
          }
        }
        return { value: series.name, text: series.name, id: series.id };
      });
      return appTypeList;
    },
    selectedRequiredDocIdList() {
      if (!this.$store.state.cmsRequiredDocSearch.selectedRequiredDocIdList) {
        return [];
      }
      return this.$store.state.cmsRequiredDocSearch.selectedRequiredDocIdList;
    },
    selectedAppidList() {
      if (!this.$store.state.cmsRequiredDocSearch.selectedAppidList) {
        return [];
      }
      return this.$store.state.cmsRequiredDocSearch.selectedAppidList;
    },
    filterdIdList() {
      return this.$store.state.cmsRequiredDocSearch.filterdIdList;
    },
    filterdAppIdList() {
      return this.$store.state.cmsRequiredDocSearch.filterdAppIdList;
    },
    canReceipt() {
      const number = this.selectedAppidList.length > 0;
      let isUnsubmit = false;
      this.selectedAppidList.forEach((appId) => {
        const selectedRequiredDocInfo = this.dbRequiredDocList.filter((item) => {
          return item.appId === appId;
        });
        if (selectedRequiredDocInfo.length > 0 && !isUnsubmit) {
          if (selectedRequiredDocInfo[0].status === 0 || selectedRequiredDocInfo[0].status === 5) {
            isUnsubmit = true;
          }
        }
      });
      return number && !isUnsubmit;
    },
    canExportPdf() {
      const { year, series } = this.search;
      // 選択があるか
      const number = this.selectedAppidList.length > 0;
      let isUnsubmit = false;
      this.selectedAppidList.forEach((appId) => {
        const selectedRequiredDocInfo = this.dbRequiredDocList.filter((item) => {
          return item.appId === appId;
        });
        if (selectedRequiredDocInfo.length > 0 && !isUnsubmit) {
          if (selectedRequiredDocInfo[0].status === 0 || selectedRequiredDocInfo[0].status === 5) {
            isUnsubmit = true;
          }
        }
      });
      return number && series && year !== null && !isUnsubmit && year !== 'all';
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  watch: {
    'search.year': async function () {
      this.$store.dispatch('page/onWaiting');
      await this.fetchList();
      this.$store.dispatch('page/offWaiting');
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onWaiting');
    await this.fetchList();
    this.$store.dispatch('page/offWaiting');
  },
};
</script>

<style>
  .cmsReportListTable thead th:nth-of-type(1) {
    width: 40px !important;
  }
  .cmsInterimListTable thead th:nth-of-type(5) {
    width: 70px !important;
  }
  .cmsReportListTable thead th:nth-of-type(7) {
    width: 120px !important;
  }
  .cmsReportListTable thead th:nth-of-type(8) {
    width: 80px !important;
  }
  .cmsReportListTable thead th:nth-of-type(9) {
    width: 80px !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
